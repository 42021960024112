<template>
  <div support>
    <Visual tit="SUPPORT" txt="현대제철이 만든 프리미엄 강재<br> <b>H CORE</b>에 대한 더 많은 정보를 만나 보실 수 있습니다" bg="support-kv"/>
    <Tab :list="selectList" :active="active" @select="select"/>
    <article>
      <Paragraph class="content inquiry" v-if="active === 'qna'" key="qna">
        <h3 class="para">Q&A</h3>
        <ul class="para">
          <li v-for="(inq, idx) in inqList" :key="inq.tit" :class="'type'+(idx+1)">
            <div class="list-cnt">
              <div class="list-img"></div>
              <div class="cnt-tit" @click="contactModal(inq);">
                <p class="tit">{{ inq.tit }}</p>
                <p class="sub">{{ inq.sub }}</p>
                <p class="inq-txt"><i></i>문의하기</p>
              </div>
            </div>
            <div class="inq-info">
              <p class="manager">{{ inq.manager }}</p>
              <p class="email"><a :href="'mailto:'+inq.email">{{ inq.email }}</a></p>
              <p class="tel"><a :href="'tel:'+inq.tel">{{ inq.tel }}</a></p>
            </div>
          </li>
        </ul>
      </Paragraph>
      <Paragraph class="content support-data" v-if="active === 'reference'" key="reference">
        <h3 class="para">Reference Data</h3>
        <div class="search-wrap para">
          <div class="filter">
            <div class="select" :class="{on:filterOpen}">
              <a class="selected"  @click="selectOpen">{{ $route.query.category || '전체보기' }}</a>
              <ul v-if="filterOpen">
                <li><a @click="filterRefs('')">전체보기</a></li>
                <li v-for="category in refsCategory" :key="category"><a @click="filterRefs(category)">{{ category }}</a></li>
              </ul>
            </div>
          </div>
          <div class="search">
            <input type="text" placeholder="검색어를 입력하세요" @keyup.enter="applySearch" v-model="search">
            <a @click="applySearch">검색</a>
          </div>
        </div>
        <ul class="sup-list para">
          <li v-for="file in files" :key="file.sq">
            <span></span>
            <p class="file-nm">{{ file.title }}</p>
            <a class="down" @click="download(file)"><i></i>DOWNLOAD</a>
          </li>
        </ul>
        <Pagination :paging="filesPage" @go="pageGo" />
      </Paragraph>
    </article>
  </div>
</template>

<script>
import Visual from "@/views/components/Visual";
import Tab from "@/views/components/Tab";
import Pagination from "@/views/components/Pagination";
import ContactModal from "@/views/components/modal/ContactModal";
import Paragraph from "@/views/components/Paragraph";
import axios from 'axios'

export default {
  name: "Support",
  components: {Paragraph, Visual, Tab, Pagination},
  data() {
    return {
      inqList: [
        { tit: '형강', sub: 'Section', manager: '이지혁 책임매니저', email: 'jhlee9@hyundai-steel.com', tel: '031.510.3461' },
        { tit: '철근', sub: 'Reinforcing Bar', manager: '정진 책임매니저', email: 'jj0923@hyundai-steel.com', tel: '031.510.3488' },
        { tit: '후판', sub: 'Plate', manager: '이창욱 책임매니저', email: 'leeco0425@hyundai-steel.com', tel: '031.510.3889' },
        { tit: '강관', sub: 'Steel Pipe', manager: '이재석 책임매니저', email: 'jslee93@hyundai-steel.com', tel: '031.510.3249' },
        { tit: '열연/냉연', sub: 'HR/CR', manager: '이경미 책임매니저 ', email: 'lkmn20@hyundai-steel.com', tel: '031.510.3862' },
        { tit: '기타', sub: 'Etc.', manager: '이현욱 책임매니저', email: 'hwlee325@hyundai-steel.com', tel: '031.510.3466' },
      ],
      search: '',
      files: [],
      filesPage: null,
      filterOpen: false,
      refsCategory: []
    }
  },
  async asyncData({ host, route }) {
    if (route.params.support === 'reference') {
      const { data } = await axios.get(host + '/api/board/refs', { params: { perPage: 10, pageNo: route.query.pageNo ?? 1, category: route.query.category, search: route.query.search, _: +new Date() } });
      return { files: data.list, filesPage: data.paging, search: route.query.search };
    }
  },
  computed: {
    active() {
      return this.$route.params.support
    },
    selectList() {
      return [
        // {key: 'engineer', label: 'Engineering Service'},
        // {key: 'tps', label: 'Total Package Service'},
        {key: 'qna', label: 'Q&A'},
        {key: 'reference', label: 'Reference Data'}
      ]
    },
  },
  methods: {
    applySearch() {
      if (this.search === this.$route.query.search) return;
      this.$router.replace({ query: { pageNo: '1', search: this.search } });
    },
    contactModal(inq) {
      this.$modal(ContactModal, {category:inq.tit});
    },
    select(support) {
      if (this.active === support) return;
      this.$router.push({ params: { support } })
    },
    async download(file) {
      await axios.get(`/api/hit/refs/${file.sq}`);
      location.href = `/api/download/${file.files[0].location}`;
    },
    pageGo(p) {
      if (`${p}` === this.$route.query.pageNo) return;
      this.$router.replace({ query: {...this.$route.query, pageNo: p } });
    },
    selectOpen() {
      this.filterOpen = !this.filterOpen;
    },
    filterRefs(v) {
      this.filterOpen = false;
      const category = v || undefined;
      if (category === this.$route.query.category) return;
      this.$router.replace({ query: {...this.$route.query, pageNo: '1', category } });
    },
  },
  async mounted() {
    const { data } = await axios.get('/api/category/refs', { params: { _: +new Date() } });
    this.refsCategory = data;
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[support] {
  article { .bgc(#fff); .rel;
    .content { .p(120,30,100); .-box; .crop;
      h3 { .hide; }
      &.inquiry {
        ul li { .wf; .mb(70);
          &:last-child { .mb(0); }
          .list-cnt { .bgc(#e6e6e6);
            .list-img { .wh(64%,26.7vw); .ib; .vat; }
            .cnt-tit { .wh(36%,100%); .p(32,0,19,30); .-box; .ib; .vat; .rel; .h(27vw);
              .tit { .fs(30); .ls(-0.025em); color:#0d1112; }
              .sub { .fs(48); .lh(48); color:#fff; .roboc; .mt(6); }
              .inq-txt { .fs(20); .lh(36); color:#fff; .abs; .lb(25,20);
                i { .wh(29,24); .contain('/images/mo/ico-inq-arrow.png'); .ib; .vam; .mr(8); }
              }
            }
          }
          .inq-info { .flex; .space-between;
            p { .fs(20); .lh(36); .mt(14); .ib; .vam; .rel; .pl(32); .-box; .spoqa; color:#0d1112; .nowrap;
              &:first-child { .pl(0); }
              &:last-child { .mr(0); }
              &:after { .cnt; .wh(24,24); .lh(24); .tc; .bgc(#e6e6e6); .abs; .lt(0,50%); transform: translateY(-50%); .br(50%); .pt(1); .-box; }
              &.manager:after { .hide; }
              &.email:after { content:'M'; }
              &.tel:after { content:'T'; }
            }
          }
          &.type1 .list-cnt .list-img { .contain('/images/mo/inq1.png'); }
          &.type2 .list-cnt .list-img { .contain('/images/mo/inq2.png'); }
          &.type3 .list-cnt .list-img { .contain('/images/mo/inq3.png'); }
          &.type4 .list-cnt .list-img { .contain('/images/mo/inq4.png'); }
          &.type5 .list-cnt .list-img { .contain('/images/mo/inq5.png'); }
          &.type6 .list-cnt .list-img { .contain('/images/mo/inq6.png'); }
        }
      }
      &.support-data {
        .search-wrap { .h(50); .tr; .clear; .m(-70,0,20); .rel; z-index: 1;
          .filter { .tl; .wh(200,50); .lh(50); .rel; .pl(0); .ib; .vam; .mr(10); .-box; z-index: 1;
            .select {
              &:after { .cnt; .wh(26,13); .contain('/images/mo/ico-select-arrow.png'); .abs; .rt(16,18); pointer-events: none; }
              .selected { .fs(18); .pointer; z-index: 1; .ib; .wh(200,50); .-a(@c-black); .pl(20); .-box; .vat; }
              ul { .wh(200,0); .abs; .lt(0,46); .-box; .-t; .bgc(#fff);  }
              &.on {
                &:after { transform: rotate(180deg); }
                ul { height:auto; .-a(@c-black); .p(15,0); .-box; .bgc(#fff); z-index: 1;
                  li { .fs(18); .-a; .f; .lh(40); .vat; background: transparent; .pl(20); .-box; .bgc(#fff);
                    &:hover { .bgc(#eee); }
                  }
                }
              }
            }
          }
          .search { .wh(300,50); .rel; .tl; .vam; .ib;
            input { .fs(18); .p(0,25); .-box; .lh(50); .wh(250,50); .-a(#000); .-r; .-box; .spoqa; }
            a { .fs(0); .wh(50,50); .bgc(@c-black); .ib; .abs; .rt;
              &:after { .cnt; .wh(20,19); .contain('/images/mo/ico-search.png'); .abs; .lt(50%,50%); transform: translate(-50%,-50%); pointer-events: none; }
            }
          }
        }
        ::placeholder { color:#bbb; }
        .sup-list {
          li { .p(18,10,18,50); .-box; .-b(#e6e7e7); .rel; .clear;
            &:first-child { .-t(#0d1112); }
            &:last-child { .-b(#0d1112); }
            &:hover span { .wh(29,24); .contain('/images/mo/ico-down-hover-arrow.png'); .ib; .abs; .lt(0,50%); transform: translateY(-50%); }
            p { .fs(30); .lh(46); .ib; .vam; .pr(30); .-box; .keep-all; .w(70%); }
            a { .fs(24); .lh(46); .ib; .vam; color:#999; .roboc; .w(30%); .tr; .pt(20); .-box;
              i { .wh(25,35); .contain('/images/mo/ico-download.png'); .mr(15); .ib; .vam; }
              &:hover { color:#333;
                i { box-shadow: 2px 2px 0 0 rgba(0,0,0,0.3); }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(min-width:1024px) {
  [support] {
    article {
      .content { .p(103,0,100); .w(1400); .mh-c;
        h3 { .fs(40); .lh(40); .bold; .mb(30); .ib; }
        &.inquiry {
          ul { .flex; .space-between; .flex-wrap;
            li { .w(650); .ib; .vat;
              &:nth-last-child(2) { .mb(0); }
              .list-cnt { .rel;
                .list-img { .wh(400,200); }
                .cnt-tit { .wh(250,200); .p(30,25); .h(200); .pointer; .rel; z-index: 1;
                  .sub { .fs(40); .lh(36); .mt(0); }
                  .inq-txt { .fs(16); .lb(30,10); }
                }
              }
              .inq-info {
                p { .fs(18); .mt(10); }
              }
              &:hover .list-cnt {
                &:after { .cnt; .f; background: rgba(0,0,0,0.6); .abs; .lt; }
                .cnt-tit {
                  .tit { color:#fff; }
                }
              }
              &.type1 .list-cnt .list-img { .contain('/images/pc/inq1.png'); }
              &.type2 .list-cnt .list-img { .contain('/images/pc/inq2.png'); }
              &.type3 .list-cnt .list-img { .contain('/images/pc/inq3.png'); }
              &.type4 .list-cnt .list-img { .contain('/images/pc/inq4.png'); }
              &.type5 .list-cnt .list-img { .contain('/images/pc/inq5.png'); }
              &.type6 .list-cnt .list-img { .contain('/images/pc/inq6.png'); }
            }
          }
        }
        &.support-data {
          h3 { .ls(-0.025em); }
          .search-wrap { .m(-80,0,30);
            .filter { .tl; .wh(200,50); .lh(50); .rel; .pl(0); .ib; .vam; .mr(10); .-box; z-index: 1;
              .select {
                &:after { .cnt; .wh(26,13); .contain('/images/mo/ico-select-arrow.png'); .abs; .rt(16,18); pointer-events: none; }
                .selected { .fs(18); .pointer; z-index: 1; .ib; .wh(200,50); .pl(20); .-a(@c-black); .-box; .vat; }
                ul { .wh(200,0); .abs; .lt(0,48); .-box; .-t; .bgc(#fff); }
                &.on {
                  &:after { transform: rotate(180deg); }
                  ul { .w(200); height:auto; .-a(@c-black); .p(15,0); .-box; .bgc(#fff); z-index: 1;
                    li { .fs(18); .-a; .f; .lh(40); .vat; background: transparent; .pl(20); .-box; .bgc(#fff);
                      &:hover { .bgc(#eee); }
                    }
                  }
                }
              }
            }
          }
          .sup-list {
            li { .p(18,30,18,50);
              p { .fs(18); }
              a { .fs(16); .pt(0); }
            }
          }
        }
      }
    }
  }
}

</style>
